let interval;
const refresh = () => {
  if(document.getElementById('page_title').innerHTML === 'Bizwiz Syncs') {
    let previousTemplate = '';
    interval = setInterval(function() {
        $.get("/admin/bizwiz_syncs/active_syncs").done((data) => {
          let template = data.split(" = ")[1];
          const diff = previousTemplate !== '' && template !== previousTemplate;
          if (diff) {
            clearInterval(interval);
            window.location.reload()
          }
          previousTemplate = template;
        })
    }, 1000);
  } else {
    clearInterval(interval);
  }

}

const destroyRefresh = () => {
  clearInterval(interval);
}


document.addEventListener("turbolinks:load", refresh);
document.addEventListener('turbolinks:before-cache', destroyRefresh)

